import './Footer.css'

const Footer = () => (
  <footer className='footer'>
    <a
      href='https://www.alexandrepinel.com'
      className='link footer__link'
      rel='noreferrer'
      target='_blank'
    >
      Created With ❤️ by Alexandre Pinel © 2021
    </a>
  </footer>
)

export default Footer
