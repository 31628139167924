const header = {
  homepage: '/',
  title: 'ED.',
}

const about = {
  name: 'Emilie Declaron',
  role: 'Translator, Interpreter and Writer',
  description: `Languages have always been one of my biggest passions. I have been working as a professional linguist for fifteen years. I am accredited by the CIOL (Chartered Institute of Linguists), the leading UK-based membership body for language professionals. My specialties include, but are not limited to, the medical, legal, fashion, marketing and literary fields.`,
  resume: '',
  social: {
    linkedin: 'https://www.linkedin.com/in/emilie-declaron-596b01b5/',
  },
}

const projects = [
  {
    name: '',
    description:
      'Feel free to contact me to know more about my projects. I may not be able to show you all of them as I often sign NDAs with private clients as well as agencies. I translate all sorts of documents: books, legal and medical documents, NGO reports and many others. ',
    stack: [],
    sourceCode: '',
    livePreview: ''
  },
]

const skills = [
  'CAT tools (including those 🐱)',
  'English',
  'French',
  'Office Suite',
  'Translation',
  'Localisation',
  'Simultaneous interpreting',
  'Consecutive interpreting',
  'Phone interpreting',
  'English teaching (TEFL)',
  'French teaching',
  'Writing',
  'Ghost writing'
]

const contact = {
  email: 'emilie.declaron@yahoo.co.uk'
}

export { header, about, projects, skills, contact }